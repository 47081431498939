<template>
  <div>
    <!-- 面包屑区域 -->
    <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card shadow="hover">
      <div class="first_floor">
        <Title :title="title[0]"></Title>
        <p class="bh">任务编号：{{orderBh}}</p>
        <!-- 列表区域  border加边框 stripe隔行变色 -->
        <el-table :data="taskCostDetails.expenseDetails" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}">
          <el-table-column label="分类" prop="type"></el-table-column>
          <el-table-column label="资金明细" prop="details"></el-table-column>
          <el-table-column label="数量" prop="num"></el-table-column>
          <el-table-column label="单价" prop="unitPrice" width="300px"></el-table-column>
          <el-table-column label="小计" prop="subtotal"></el-table-column>
        </el-table>

        <div class="total">
            合计：<span class="money">{{taskCostDetails.allmoney}}</span>元        
        </div>
      </div>

      <div class="second_floor">
        <Title :title="title[1]"></Title>

        <div class="total">
            合计：<span class="money">{{taskCostDetails.allmoney}}</span>元        
        </div>

        <div class="paymethod">
            <el-radio v-model="paymethod" label="1">使用账户余额支付（{{taskCostDetails.usermoney}}元）</el-radio>
            <el-button type="primary" size="mini" class="recharge" @click="goRecharge">去充值</el-button>
        </div>
      </div>
    </el-card>

    <div class="payment_box">
            <el-button type="primary" class="payment" :disabled="taskCostDetails.status" @click="payment">立即支付</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      title: ["任务费用明细", "选择支付方式"],
      //面包屑数据
      breadcrumbData: [
        {
          id: 1,
          title: "好物电商",
          path: "",
          isClick: false
        },
        {
          id: 2,
          title: "任务管理",
          path: "",
          isClick: false
        },
        {
          id: 3,
          title: "发布任务",
          path: "",
          isClick: false
        },
        {
          id: 4,
          title: "垫付任务发布",
          path: "",
          isClick: false
        },
        {
          id: 5,
          title: "支付核对",
          path: "",
          isClick: false
        }
      ],
      //费用明细数据
      expenseDetails:[
          {
              id:1,
              type:'本金',
              details:'本金',
              num:1,
              unitPrice:'99.99',
              subtotal:'99.99'
          },
          {
              id:2,
              type:'平台技术服务费',
              details:'平台技术服务费',
              num:1,
              unitPrice:'1.00',
              subtotal:'1.00'
          },
          {
              id:3,
              type:'用户服务费',
              details:'用户服务费',
              num:1,
              unitPrice:'3.00',
              subtotal:'3.00'
          }
      ],
      money:'99999.99',
      paymethod:'1',
      orderBh:''
    };
  },
  computed:{
      ...mapState({
          thirdBhCode:state => state.publish.thirdBhCode,
          taskCostDetails:state => state.publish.taskCostDetails
      }),
  },
  methods:{
     //获取任务费用明细
     getTaskCostDetails(bh){
         this.$store.dispatch('getTaskCostDetails',bh)
     },
     //去充值
     goRecharge(){
         this.$router.replace({path:'/recharge'})
     },

     //立即支付
     payment(){
          // 打开提示弹窗
          this.$confirm('是否确认使用余额支付？', '提示', {
               closeOnClickModal:false,
               distinguishCancelAndClose: true,
               confirmButtonText: '确认',
               cancelButtonText: '取消',
               type: 'warning',
               center: true
        })
          .then(async () => {
               try{
                    await this.$store.dispatch('orderPayment',this.orderBh)
                    this.$message.success("支付成功！");
                    setTimeout(() => {
                         localStorage.removeItem('taskInfo')
                         this.$router.replace({path:'/advancepayment'})
                    }, 1000);
               }catch(error){
                     this.$message.error(error.message);
               }
          })
          .catch(action => {
                    this.$message.warning("您已取消支付，请尽快支付！");
          });
     }
  },
  mounted(){
    if(this.$route.query.bh){
        this.getTaskCostDetails(this.$route.query.bh)
        this.orderBh = this.$route.query.bh
    }else{
       this.getTaskCostDetails(this.thirdBhCode)
        this.orderBh = this.thirdBhCode
    }
  }
};
</script>

<style lang="less" scoped>
.first_floor {
  width: 100%;
  height: auto;
  .bh{
      color:#a2a2a2;
      font-size:16px;
      margin:20px 0 20px;
      text-align:left;
  }
  .total{
      margin-top:30px;
      font-size:20px;
      color:#a2a2a2;
      font-weight:500;
      text-align:right;
      padding-right:50px;
      .money{
          color:#ff9940;
          font-size:35px;
          font-weight:700;
      }
  }
}
.second_floor{
    margin-top:30px;
    .total{
      margin-top:20px;
      font-size:20px;
      color:#a2a2a2;
      font-weight:500;
      text-align:left;
      .money{
          color:#ff9940;
          font-size:35px;
          font-weight:700;
      }
    }
    .paymethod{
        margin-top:10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size:20px;
        .recharge{
              width:120px;
              height:40px;
              font-size:19px;
              font-weight:700;
        }
    }
}

.payment_box{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:100px;
    .payment{
       width:400px;
       height:50px;
       font-size:18px;
    }
}

.el-card {
  margin: 20px 20px 0 20px;
}
</style>